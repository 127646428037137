import Cookies from "js-cookie";

const TokenKey = "LoginAuthToken";

// export function getToken() {
//   return Cookies.get(TokenKey);
//   // return localStorage.getItem(TokenKey)
// }

// export function setToken(token) {
//   var value = "Bearer:" + token;
//   return Cookies.set(TokenKey, value, { expires: 7 });
//   // return localStorage.setItem(TokenKey, value)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey);
//   // return localStorage.removeItem(TokenKey)
// }

export default {
  getToken() {
    return Cookies.get(TokenKey);
  },

  setToken(token) {
    var value = "Bearer:" + token;
    return Cookies.set(TokenKey, value, { expires: 7 });
  },

  removeToken() {
    return Cookies.remove(TokenKey);
  },

  isLogin() {
    const token = this.getToken();
    return token;
  },
};
