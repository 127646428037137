import { post } from "./request";

/// 提交联系方式
export function saveContactInfo(data) {
  return post("/api/frontapi/registerOrder/save", data);
}

/// 获取项目的配置数据
export function getConfigData() {
  return post("/api/frontapi/basic/allDicts");
}
