export const StorageKey = {
  /// 国籍
  nationality: "nationality-storage",
  /// 性别
  gender: "gender-storage",
  /// 血型
  bloodType: "blood-type-storage",
  ///
  age: "age-storage",
};

export default {
  getData(key) {
    return JSON.parse(localStorage.getItem(key));
  },

  setData(key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  },

  removeData(key) {
    return localStorage.removeItem(key);
  },
};
