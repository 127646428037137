<template>
  <div id="bottom_tabbar">
    <b-row>
      <b-col
        v-for="(item, index) in tabbars"
        :key="index"
        :id="index"
        class="py-2"
      >
        <b-link :to="item.path" :href="item.href">
          <b-icon
            :icon="item.path == tabpath ? item.icn2 : item.icn1"
            font-scale="2.2"
          ></b-icon>
          <div>{{ item.name }}</div>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Tabbar",
  props: {
    tabpath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tab: "tab",
      timepartValue: "",
      tabbars: [
        {
          icn1: "house",
          icn2: "house-fill",
          name: "頭條",
          path: "/home",
        },
        {
          icn1: "pie-chart",
          icn2: "pie-chart-fill",
          name: "捐卵",
          path: "/donator",
        },
        // {
        //   icn1: "bootstrap",
        //   icn2: "bootstrap-fill",
        //   name: "愛心",
        //   path: "/surrogacy/list",
        // },
        {
          icn1: "heart",
          icn2: "heart-fill",
          name: "套餐",
          path: "/package",
        },
        {
          icn1: "envelope",
          icn2: "envelope-fill",
          name: "關於",
          path: "/aboutus",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
#bottom_tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 52px;
  background-color: #fff;
  z-index: 999;
  border-top: 1px solid #e6e6e6;
  text-align: center;

  a {
    color: #333;
    font-size: 9px;
  }

  a:hover {
    text-decoration: none;
  }

  a.router-link-exact-active,
  a.router-link-active {
    color: #1fb9de;
  }
}
</style>
