import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import auth from "@/utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/Login"),
    meta: {
      title: "手机号登录",
    },
  },
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/home/index"),
        meta: {
          auth: false,
          title: "首頁",
          tabbar: true,
          deepth: 1,
          keepAlive: true,
        },
      },
      {
        path: "community/detail",
        name: "CommunityDetail",
        component: () =>
          import(
            /* webpackChunkName: "Home" */ "@/views/home/community/Detail"
          ),
        meta: {
          auth: false,
          title: "內容詳情",
          deepth: 2,
        },
      },
    ],
  },
  {
    path: "/donator",
    component: Layout,
    redirect: "/donator/list",
    children: [
      // {
      //   path: "",
      //   name: "Donator",
      //   component: () =>
      //     import(/* webpackChunkName: "Donator" */ "@/views/donator/index"),
      //   meta: {
      //     auth: false,
      //     title: "捐助",
      //     tabbar: true,
      //     deepth: 1,
      //     keepAlive: true,
      //     hideContactFloatingAction: true,
      //   },
      // },
      {
        path: "list",
        name: "DonatorList",
        component: () =>
          import(/* webpackChunkName: "Donator" */ "@/views/donator/List"),
        meta: {
          auth: false,
          title: "捐卵",
          tabbar: true,
          deepth: 2,
          keepAlive: true,
          hideContactFloatingAction: true,
        },
      },
      {
        path: "detail",
        name: "DonatorDetail",
        component: () =>
          import(/* webpackChunkName: "Donator" */ "@/views/donator/Detail"),
        meta: {
          auth: false,
          title: "捐卵詳情",
          deepth: 3,
          hideContactFloatingAction: true,
        },
      },
    ],
  },
  {
    path: "/surrogacy",
    component: Layout,
    redirect: "/surrogacy/list",
    children: [
      // {
      //   path: "",
      //   name: "Surrogacy",
      //   component: () =>
      //     import(/* webpackChunkName: "Surrogacy" */ "@/views/surrogacy/index"),
      //   meta: {
      //     auth: false,
      //     title: "愛心媽媽",
      //     tabbar: true,
      //     deepth: 1,
      //     keepAlive: true,
      //     hideContactFloatingAction: true,
      //   },
      // },
      {
        path: "list",
        name: "SurrogacyList",
        component: () =>
          import(/* webpackChunkName: "Surrogacy" */ "@/views/surrogacy/List"),
        meta: {
          auth: false,
          title: "愛心媽媽",
          tabbar: true,
          deepth: 2,
          keepAlive: true,
          hideContactFloatingAction: true,
        },
      },
      {
        path: "detail",
        name: "SurrogacyDetail",
        component: () =>
          import(
            /* webpackChunkName: "Surrogacy" */ "@/views/surrogacy/Detail"
          ),
        meta: {
          auth: false,
          title: "愛心媽媽詳情",
          deepth: 3,
          hideContactFloatingAction: true,
        },
      },
    ],
  },
  {
    path: "/package",
    component: Layout,
    children: [
      {
        path: "",
        name: "Package",
        component: () =>
          import(/* webpackChunkName: "Package" */ "@/views/package/index"),
        meta: {
          auth: false,
          title: "套餐",
          tabbar: true,
          deepth: 1,
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/aboutus",
    component: Layout,
    children: [
      {
        path: "",
        name: "Aboutus",
        component: () =>
          import(/* webpackChunkName: "Aboutus" */ "@/views/aboutus/index"),
        meta: {
          auth: false,
          title: "關於",
          tabbar: true,
          deepth: 1,
          keepAlive: true,
        },
      },
      // {
      //   path: "contactus",
      //   name: "Contactus",
      //   component: () =>
      //     import(/* webpackChunkName: "Aboutus" */ "@/views/aboutus/Contactus"),
      //   meta: {
      //     auth: false,
      //     title: "聯繫我們",
      //     deepth: 5,
      //     hideContactFloatingAction: true,
      //   },
      // },
    ],
  },
  {
    path: "/contactus",
    name: "Contactus",
    component: () =>
      import(/* webpackChunkName: "Aboutus" */ "@/views/aboutus/Contactus"),
    meta: {
      auth: false,
      title: "聯繫我們",
      deepth: 5,
      hideContactFloatingAction: true,
    },
  },
  {
    path: "*",
    name: "Error404",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/Error404.vue"),
    meta: {
      title: "404",
    },
  },
];

const router = new VueRouter({
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

/// 全局前置守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }

  // set page title
  // document.title = getPageTitle(to.meta.title);
  document.title = to.meta.title + " - 台灣同性生育";

  if (to.matched.some((record) => record.meta.auth)) {
    const isAuthenticated = auth.isLogin();
    if (to.name !== "Login" && !isAuthenticated) {
      next({ name: "Login", query: { redirect: to.fullPath } });
      // next({
      //   path: "/login",
      //   query: { redirect: to.fullPath },
      // });
    } else next();
  } else next();
});

export default router;
