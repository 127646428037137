<template>
  <div id="topNavbar" :class="defaultHeadClass">
    <b-container>
      <b-navbar toggleable="md">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-brand to="/">
          <div class="nav-brand-box">
            <b-img-lazy
              v-bind="mainProps"
              blank-color="#ccc"
              src="@/assets/logo.png"
              alt="台灣同性生育"
              rounded="0"
            ></b-img-lazy>
            <div class="nav-title ml-1 ml-md-2 text-left">
              <div class="nav-title-cn">第壹生育集團</div>
              <div v-if="!isMobile" class="nav-title-en">FFPGS</div>
            </div>
          </div>
        </b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <!-- <b-navbar-nav class="mr-auto"></b-navbar-nav> -->
          <b-navbar-nav class="ml-5">
            <b-nav-item
              v-for="(nav, index) in navBarArr"
              :key="nav.to"
              :to="nav.to"
              :active="activeIndex == index"
              >{{ nav.title }}</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
        <b-navbar-nav class="mr-auto">
          <!-- <b-button class="btn__search" @click="doSearch">
            <b-icon icon="search" font-scale="1.1"></b-icon>
          </b-button> -->
        </b-navbar-nav>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NavBar",
  components: {},
  data() {
    return {
      fixed: true,
      mainProps: { blank: true, width: 42, class: "mr-0" },
      navBarArr: [
        { title: "首頁", to: "/home" },
        { title: "捐卵", to: "/donator" },
        // { title: "愛心", to: "/surrogacy" },
        { title: "套餐", to: "/package" },
        { title: "關於", to: "/aboutus" },
      ],
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),
    defaultHeadClass() {
      var className = " custom-nav ";
      if (this.fixed) {
        className += " fixed-top ";
      }
      if (this.$route.meta.navTransparent) {
        className += " bg-transparent ";
      }
      if (this.isMobile) {
        className += " border-bottom bg-white ";
      } else {
        // className += " shadow ";
      }
      return className;
    },
    fullPath() {
      return this.$route.fullPath;
    },
    path() {
      return this.$route.path;
    },
    activeIndex() {
      var index = -1;
      this.navBarArr.forEach((item, idx) => {
        if (this.path.indexOf(item.to) > -1) {
          index = idx;
        }
      });
      return index;
    },
  },
  methods: {
    gotoLogin() {
      this.$router.push({ name: "Login" });
    },
    $_scrollHandler() {
      if (this.$route.meta.navTransparent) {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let bannerH = document.getElementById("homebanner").offsetHeight;
        if (scrollTop >= bannerH - 60) {
          document
            .getElementById("topNavbar")
            .classList.remove("bg-transparent");
        } else {
          document.getElementById("topNavbar").classList.add("bg-transparent");
        }
      }
    },
    doSearch() {
      this.$router.push({
        name: "ContentSearch",
      });
    },
  },
  beforeMount() {
    // window.addEventListener("scroll", this.$_scrollHandler);
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.$_scrollHandler);
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.custom-nav {
  z-index: 99;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

  .nav-brand-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .nav-title-cn,
    .nav-title-en {
      color: var(--primary);
    }

    .nav-title-cn {
      font-size: 0.9rem;
      font-weight: bold;
    }

    .nav-title-en {
      font-size: 0.75rem;
    }
  }

  .navbar-nav {
    .nav-item {
      padding: 0px;
    }

    .nav-link {
      padding: 0px;
      padding-bottom: 0.25rem;
      margin: 0rem 1.35rem;
    }

    a {
      display: block;
      font-size: 1rem;
      color: #000;
      text-decoration: none;
      border-bottom: 3px solid transparent;
      // margin: 0rem 1.35rem;

      &.router-link-exact-active {
        font-weight: bold;
        display: inline-block;
        color: var(--primary);
        border-bottom-color: var(--primary);
      }

      &:hover {
        font-weight: bold;
        color: var(--primary);
        border-bottom-color: var(--primary);
      }

      &.active {
        font-weight: bold;
        color: var(--primary);
        border-bottom-color: var(--primary);
      }
    }
  }

  .btn__search,
  .btn__search:active {
    color: #000;
    background-color: transparent;
    margin-bottom: 10px;

    border: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
  }

  .btn__search:hover {
    color: var(--primary);
  }
}

.custom-nav.fixed-top {
  position: fixed;
  top: 0;
}

.custom-nav.bg-transparent {
  background-color: transparent;
  box-shadow: none;

  .navbar-brand,
  .search-bar {
    display: none;
  }

  .btn__search,
  .btn__search:active {
    color: #fff;
  }

  .btn__search:hover {
    color: #fff;
  }

  .navbar-nav {
    a {
      color: #fff;
      &.router-link-exact-active {
        color: #fff;
        border-bottom-color: #fff;
      }
      &:hover {
        color: #fff;
        border-bottom-color: #fff;
      }
    }
  }
}
</style>
