export const debug = process.env.NODE_ENV !== "production";

export const baseUrl = "https://www.lgbtbb.net";
// export const baseUrl = "http://test.lgbtbb.com";

export const imgHost = "https://www.lgbtbb.net";

const configs = {};

export default configs;
