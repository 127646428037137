import axios from "axios";
import auth from "@/utils/auth";
import { baseUrl } from "@/utils/config";
import { MessageBox } from "mint-ui";
import router from "@/router";
import store from "@/store";

// create an axios instance
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 5000, // request timeout
});

// request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = auth.getToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
instance.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      // const msg = res.message || "Error";
      // Toast(msg);

      // 401: Illegal token; 50012: Other clients logged in; 205: Token expired;
      if (res.code === 205 || res.code === 401) {
        MessageBox.alert("您的帐号已退出或失效", "重新登录", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          showClose: false,
        }).then(() => {
          store.dispatch("user/cleanToken").then(() => {
            // location.reload();
            router.push("/login");
          });
        });
      }

      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    // const msg = error.message || "Error";
    // Toast(msg);
    return Promise.reject(error);
  }
);

export default instance;

export function get(url, params) {
  return instance({ url: url, method: "get", data: params });
}

export function post(url, params) {
  return instance({ url: url, method: "post", data: params });
}

export function getUrl(url, params) {
  return baseUrl + url + params;
}
