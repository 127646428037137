import store from "@/store";

const { body } = document;
// refer to Bootstrap's responsive design
const WIDTH = 768;

export default {
  watch: {
    $route() {
      // console.log(to, from);
      // if (this.device === "mobile") { }
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.$_resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.$_resizeHandler);
  },
  mounted() {
    const isMobile = this.$_isMobile();
    const device = isMobile ? "mobile" : "desktop";
    store.dispatch("app/toggleDevice", device);
  },
  updated() {},
  methods: {
    // use $_ for mixins properties
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile();
        const device = isMobile ? "mobile" : "desktop";
        store.dispatch("app/toggleDevice", device);
      }
    },
  },
};
