// initial state
const state = () => ({
  device: "mobile",
  isMobile: true,
});

// getters
const getters = {};

// mutations
const mutations = {
  toggleDevice(state, device) {
    state.device = device;
    state.isMobile = device === "mobile";
  },
  checkDeviceType(state, isMobile) {
    state.isMobile = isMobile;
  },
};

// actions
const actions = {
  toggleDevice({ commit }, device) {
    commit("toggleDevice", device);
    // commit("checkDeviceType", device === "mobile");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
