<template>
  <div :class="classObj">
    <template v-if="!isMobile">
      <NavBar />
      <div class="top-area"></div>
    </template>
    <!-- <NavBar /> -->

    <AppMain />
    <!-- 暂不可使用AppMain，否则分页加载更多时会出问题 -->
    <!-- <router-view></router-view> -->
    <!-- <keep-alive :include="include">
      <router-view v-if="isKeepAlive" />
    </keep-alive>
    <router-view v-if="!isKeepAlive" :key="key" /> -->

    <template v-if="isMobile">
      <div class="bottom-area"></div>
      <AppTabbar v-if="showTabbar" :tabpath="tabPath" />
    </template>

    <app-footer v-if="!isMobile"></app-footer>

    <app-floating-action></app-floating-action>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import AppMain from "@/components/AppMain.vue";
import AppFooter from "@/components/AppFooter.vue";
import AppTabbar from "@/components/AppTabbar";
import AppFloatingAction from "@/components/AppFloatingAction";
import ResizeMixin from "@/layout/mixin/resize-mixin";
import { mapState } from "vuex";

export default {
  name: "Layout",
  components: { NavBar, AppMain, AppTabbar, AppFooter, AppFloatingAction },
  mixins: [ResizeMixin],
  data() {
    return {
      include: [],
      tabPath: null,
      showTabbar: false,
    };
  },
  computed: {
    ...mapState({ isMobile: (state) => state.app.isMobile }),
    classObj() {
      return { mobile: this.isMobile };
    },
    key() {
      return this.$route.fullPath;
    },
    isKeepAlive() {
      const keepAlive = this.$route.meta?.keepAlive || false;
      return keepAlive;
    },
  },
  watch: {
    $route(to, from) {
      this.tabPath = to.path;
      this.showTabbar = this.$route.meta.tabbar;

      // 如果要to(进入)的页面是需要keepAlive缓存的，把name push进include数组中
      if (to.meta.keepAlive) {
        !this.include.includes(to.name) && this.include.push(to.name);
      }
      // 如果 要 form(离开) 的页面是 keepAlive缓存的，
      // 再根据 deepth 来判断是前进还是后退
      // 如果是后退：
      if (from.meta.keepAlive && to.meta.deepth < from.meta.deepth) {
        const index = this.include.indexOf(from.name);
        index !== -1 && this.include.splice(index, 1);
      }
    },
  },
  created() {
    this.tabPath = this.$route.path;
    this.showTabbar = this.$route.meta.tabbar;
  },
};
</script>
