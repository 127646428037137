import Vue from "vue";
import SvgIcon from "@/components/SvgIcon"; // svg component
// import { DatetimePicker } from "mint-ui";

// register globally
Vue.component("svg-icon", SvgIcon);

// Vue.component(DatetimePicker.name, DatetimePicker);

const req = require.context("./svg", false, /\.svg$/);
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
requireAll(req);
