import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { currencyFormatter } from "@/utils/currency";

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Mint from "mint-ui";
import "mint-ui/lib/style.css";
import "./icons";
import "./styles/custom.css";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

Vue.use(Element);

Vue.use(Mint);

Vue.use(VueViewer);

Vue.filter("currencyFormat", currencyFormatter);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
