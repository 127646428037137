import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import cart from "./modules/cart";
import products from "./modules/products";
import user from "./modules/user";
import config from "./modules/config";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: { app, cart, products, config, user },
  strict: debug,
});
