<template>
  <div class="app-floating-action">
    <b-button
      v-if="showTopBtn"
      class="floating__item btn__top"
      @click="onClickToTop"
    >
      <b-icon icon="chevron-up" font-scale="1.8"></b-icon>
    </b-button>
    <b-button
      v-if="showContactBtn"
      class="floating__item btn__contact"
      @click="onClickToContact"
    >
      <b-icon icon="envelope" font-scale="1.8"></b-icon>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "AppFloatingAction",
  data() {
    return {
      showTopBtn: false,
      showContactBtn: false,
      screenHeight: 1200,
    };
  },
  methods: {
    onClickToTop() {
      window.scrollTo(0, 0);
    },
    onClickToContact() {
      let routeData = this.$router.resolve({ name: "Contactus" });
      window.open(routeData.href, "_blank");
    },
    $_scrollHandler() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.showTopBtn = scrollTop >= this.screenHeight;
    },
  },
  watch: {
    $route(to) {
      let names = [
        "Contactus",
        "Donator",
        "DonatorList",
        "DonatorDetail",
        "Surrogacy",
        "SurrogacyList",
        "SurrogacyDetail",
      ];
      this.showContactBtn = true;

      if (names.indexOf(to.name) > -1) {
        this.showContactBtn = false;
      }
    },
  },
  mounted() {
    this.screenHeight = window.screen.height;
    this.showContactBtn = !this.$route.meta.hideContactFloatingAction;
  },
  beforeMount() {
    window.addEventListener("scroll", this.$_scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.$_scrollHandler);
  },
};
</script>

<style lang="scss">
.mobile .app-floating-action {
  right: 5px;
  bottom: 80px;
}
.app-floating-action {
  position: fixed;
  right: 15px;
  bottom: 40px;
  z-index: 9499;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px #ddd;
  border-radius: 4px;
  overflow: hidden;
  background-color: #eee;

  .floating__item {
    color: #222;
    // background-color: rgb(31, 185, 222);
    background-color: #fff;
    border: none;
    border-radius: 0px;
    overflow: hidden;

    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .floating__item:nth-child(2) {
    margin-top: 1px;
  }

  .floating__item:hover {
    background-color: var(--primary);
    color: #fff;
  }
}
</style>
