<template>
  <div id="app">
    <router-view />
    <!-- <keep-alive :include="include">
      <router-view v-if="isKeepAlive" />
    </keep-alive>
    <router-view v-if="!isKeepAlive" :key="key" /> -->
  </div>
</template>

<script>
export default {
  name: "AppMain",
  data() {
    return {
      include: [],
    };
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isKeepAlive() {
      const keepAlive = this.$route.meta?.keepAlive || false;
      return keepAlive;
    },
  },
  watch: {
    $route(to, from) {
      // 如果要to(进入)的页面是需要keepAlive缓存的，把name push进include数组中
      if (to.meta.keepAlive) {
        !this.include.includes(to.name) && this.include.push(to.name);
      }
      // 如果 要 form(离开) 的页面是 keepAlive缓存的，
      // 再根据 deepth 来判断是前进还是后退
      // 如果是后退：
      if (from.meta.keepAlive && to.meta.deepth < from.meta.deepth) {
        const index = this.include.indexOf(from.name);
        index !== -1 && this.include.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  font-size: 16px;
}

.page {
  margin-top: 1px;
  padding: 16px 0px;
}

.mobile {
  .page {
    margin-top: 0px;
    padding: 0px 0px;
  }
}
</style>
