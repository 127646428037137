import { post } from "./request";

export default {
  /// 获取客户端唯一标识码
  getClientID() {
    return post("/api/auth/getClientID");
  },

  /// 获取验证码
  getPhoneCode(data) {
    return post("/api/auth/sendLoginMsg", data);
  },

  /// 手机号登录
  loginByPhone(data) {
    return post("/api/auth/verifyLoginSmsCode", data);
  },

  /// 帐号密码登录
  loginByPwd(data) {
    return post("/api/auth/login", data);
  },

  getUserInfo() {
    return post("/api/auth/getAccountDetail");
  },
};
