<template>
  <div class="app-footer py-5">
    <b-container>
      <b-row>
        <!-- <b-col class="px-0" offset-md="1" md="10" lg="7"> -->
        <b-col>
          <div class="footer-section">
            <div class="footer__title mb-3" @click="gotoContactus">
              <span>聯繫我們</span>
              <b-icon icon="envelope" class="ml-2"></b-icon>
            </div>
            <div class="footer__info">
              <div class="info__desc">總機電話：(66)81468-0148</div>
              <div class="info__desc">Line / Whatsapp ： +66 814680148</div>
            </div>
          </div>
          <!-- <div class="copyright">
        <span class="mr-1">版权所有</span>
        <span>©2020-2021 第壹試管嬰兒中心(香港)有限公司</span>
      </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    gotoContactus() {
      let routeData = this.$router.resolve({ name: "Contactus" });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "Contactus",
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-footer {
  background-color: var(--light);
  color: var(--gray);
  font-size: 0.5rem;

  .footer__title {
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .footer__info {
    .info__desc {
      font-size: 1rem;
      line-height: 2rem;
    }
  }

  .copyright {
    font-size: 0.5rem;
    text-shadow: 0.1px 0.1px 0.5px #f0f0f0;
  }
}
</style>
