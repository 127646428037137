import { getConfigData } from "@/utils/api/common";
// import { getData, setData, StorageKey } from "@/utils/storage";
import storage, { StorageKey } from "@/utils/storage";

const ageArr = [
  { key: 1, name: "20歲以內" },
  { key: 2, name: "21~25歲" },
  { key: 3, name: "26~30歲" },
  { key: 4, name: "30歲以上" },
];

// const state = () => ({
//   bloodTypes: storage.getData(StorageKey.bloodType),
//   educations: storage.getData(StorageKey.education),
//   nationalities: storage.getData(StorageKey.nationality),
//   races: storage.getData(StorageKey.race),
//   tags: storage.getData(StorageKey.tag),
//   genders: storage.getData(StorageKey.gender),
//   ages: ageArr,
// });

const state = () => ({
  bloodTypes: storage.getData(StorageKey.bloodType),
  // educations: storage.getData(StorageKey.education),
  nationalities: storage.getData(StorageKey.nationality),
  // races: storage.getData(StorageKey.race),
  // tags: storage.getData(StorageKey.tag),
  genders: storage.getData(StorageKey.gender),
  ages: ageArr,
});

// initial state
// const state = () => ({
//   nationalities: null,
//   bloodTypes: null,
//   genders: null,
//   ages: null,
// });

// getters
// const getters = {
//   nationalities: (state) => {
//     if (state.nationalities) {
//       console.log("======8887");

//       return state.nationalities;
//     } else {
//       console.log("======8888");
//       let info = storage.getData(StorageKey.nationality);
//       return info;
//     }
//   },
//   bloodTypes: (state) => {
//     if (state.bloodTypes) {
//       return state.bloodTypes;
//     } else {
//       let info = storage.getData(StorageKey.bloodType);
//       return info;
//     }
//   },
//   genders: (state) => {
//     if (state.genders) {
//       return state.genders;
//     } else {
//       let info = storage.getData(StorageKey.gender);
//       return info;
//     }
//   },
//   ages: (state) => {
//     if (state.ages) {
//       return state.ages;
//     } else {
//       let info = ageArr;
//       return info;
//     }
//   },
// };

const mutations = {
  SET_BLOOD_TYPE: (state, data) => {
    state.bloodTypes = data;
  },
  // SET_EDUCATION: (state, data) => {
  //   state.educations = data;
  // },
  SET_NATIONALITY: (state, data) => {
    state.nationalities = data;
  },
  // SET_RACE: (state, data) => {
  //   state.races = data;
  // },
  SET_GENDER: (state, data) => {
    state.genders = data;
  },
  // SET_TAG: (state, data) => {
  //   state.tags = data;
  // },
};

const actions = {
  // 获取应用的配置数据
  getConfigData({ commit }) {
    // const nationalities = storage.getData(StorageKey.nationality);
    // if (
    //   !nationalities ||
    //   (nationalities instanceof Array && nationalities.length === 0)
    // ) {
    //   //
    // } else {
    //   return;
    // }

    return new Promise((resolve, reject) => {
      getConfigData()
        .then((res) => {
          const respData = res.data;
          const bloodTypes = respData?.bloodTypes || [];
          // const educations = respData?.educations || [];
          const nationalities = respData?.nationalities || [];
          // const races = respData?.races || [];
          const sexes = respData?.sexes || [];
          // const tags = respData?.tags || [];

          commit("SET_NATIONALITY", nationalities);
          commit("SET_BLOOD_TYPE", bloodTypes);
          // commit("SET_EDUCATION", educations);
          // commit("SET_RACE", races);
          commit("SET_GENDER", sexes);
          // commit("SET_TAG", tags);

          storage.setData(StorageKey.nationality, nationalities);
          storage.setData(StorageKey.bloodType, bloodTypes);
          // storage.setData(StorageKey.education, educations);
          // storage.setData(StorageKey.race, races);
          storage.setData(StorageKey.gender, sexes);
          // storage.setData(StorageKey.tag, tags);

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  mutations,
  actions,
};
