import user from "@/utils/api/user";
import auth from "@/utils/auth";
import storage, { StorageKey } from "@/utils/storage";

// initial state
const state = () => ({
  token: null,
  info: null,
  phone: null,
  userType: 0, //0-客户; 1-内部员工;
});

// getters
const getters = {
  loginToken: (state) => {
    if (state.token) {
      return state.token;
    } else {
      let token = auth.getToken();
      return token;
    }
  },
  userInfo: (state) => {
    if (state.info) {
      return state.info;
    } else {
      let info = storage.getData(StorageKey.userInfo);
      return info;
    }
  },
};

// mutations
const mutations = {
  setToken(state, token) {
    state.token = token;
  },

  setInfo(state, info) {
    state.info = info;
  },

  setPhone(state, phone) {
    state.phone = phone;
  },

  setUserType(state, type) {
    state.userType = type;
  },
};

// actions
const actions = {
  loginByPhone({ commit }, params) {
    return new Promise((resolve, reject) => {
      user
        .loginByPhone(params)
        .then((res) => {
          const token = res.data;
          if (res.code == 200 && token) {
            auth.setToken(token);
            commit("setToken", token);
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      user
        .getUserInfo()
        .then((res) => {
          let uinfo = res.data;
          if (res.code == 200 && res.data) {
            storage.setData(StorageKey.userInfo, uinfo);
            commit("setInfo", uinfo);
            commit("setPhone", uinfo.mobile);
            commit("setUserType", uinfo.customerType);
            resolve();
          } else {
            commit("setInfo", null);
            reject();
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  cleanToken({ commit }) {
    auth.removeToken();
    storage.removeData(StorageKey.userInfo);
    commit("setToken", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
